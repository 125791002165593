<template>
  <div>
    {{ __("401 page") }}
  </div>
</template>

<script>
export default {
  name: "NotAuthorized"
};
</script>

<style lang="scss" scoped></style>
